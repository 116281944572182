import React, {Component} from "react";
import "./NoAccessPage.css";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import "firebase/auth";
import {Link} from "react-router-dom";
import StorageService from "../features/storage/StorageService";
import Sharks from "../features/../components/Sharks";

export default class NoAccessPage extends Component {
    componentWillMount() {
        const darkMode = StorageService.getDarkMode();
        this.setState({
            darkModeOn: darkMode
        });

        this.uiConfig = {
            signInFlow: 'popup',
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
            ],
            signInSuccessUrl: `/${this.props.listID}`,
        };

        switch (this.props.type) {
            case "error":
                this.title = "An unknown error has occurred.";
                this.subtitle = "Something went wrong... Please try again later.";
                this.content = <>
                    <div className="ls-button width-80"><Link to="/" style={{width: '100%'}}>Go back to Listshare</Link></div>
                    <p>If this error occurs often, please contact support.</p>
                </>;
                break;
            case "no-access":
                this.title = "The creator of this list has set it to private";
                this.subtitle = "Unfortunately you don't have access to this list.";
                this.content = <>
                    <div className="ls-button ls-button-primary width-80" onClick={() => alert("This feature still has to be implemented")}>Request access to this list</div>
                    <div className="or">OR</div>
                    <div className="ls-button width-80"><Link to="/" style={{width: '100%'}}>Learn more about Listshare</Link></div>
                </>;
                break;
            case "no-access-without-login":
                this.title = "The creator of this list has set it to private";
                this.subtitle = "Sign in to access this list";
                this.content = <>
                    <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
                    <div className="or">OR</div>
                    <div className="ls-button width-80"><Link to="/" style={{width: '100%'}}>Learn more about Listshare</Link></div>
                </>;
                break;
            case "not-the-owner":
                this.title = "No access";
                this.subtitle = "You are not the owner of this list";
                this.content = <>
                    <div className="ls-button width-80"><Link to={`/${this.props.listID}`} style={{width: '100%'}}>Go to list page</Link></div>
                    <div className="or">OR</div>
                    <div className="ls-button width-80"><Link to="/" style={{width: '100%'}}>Learn more about Listshare</Link></div>
                </>;
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div style={{height: "100vh", overflow: "hidden"}} className={this.state.darkModeOn ? "dm" : null}>
                <div className="no-access-page-container">
                    <h2>{this.title}</h2>
                    <p>{this.subtitle}</p>
                </div>
                <div className="nap-bottom">
                    <Sharks/>

                    <div className="nap-bottom-content">
                        {this.content}
                    </div>
                </div>
            </div>
        )
    }
}