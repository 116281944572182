import React, {useEffect} from "react";

import {observer, useLocalStore} from "mobx-react-lite";
import {useStyleStore} from "../../stores/PropertyStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Sharks from "../../components/Sharks";
import FirebaseService from "../../services/FirebaseService";
import "./ListAccessPage.scss";
import TagService from "../tags/TagService";
import ListService from "../../services/ListService";
import NoAccessPage from "../../components/NoAccessPage";
import {MenuDialog} from "../menu/MenuDialogs";

export const ListAccessPage = observer(props => {
    const propertyStore = useStyleStore();

    const store = useLocalStore(() => ({
        loaded: false,
        people: [],
        noSecurity: false,
        noAccess: false,
        userID: null,
        openDialog: null,
        personToRevokeAccessFrom: null
    }));

    useEffect(() => {
        propertyStore.id = props.match.params.listID;

        FirebaseService.getIdToken(async (idToken, userID) => {
            propertyStore.idToken = idToken;
            store.userID = userID;

            ListService.getUserAccess(props.match.params.listID, idToken).then(async res => {
                if (!res) return;

                if (res === "NO ACCESS") {
                    store.noAccess = true;
                    return;
                }

                if (res.hasOwnProperty("result")) {
                    store.noSecurity = true;
                    return;
                }

                store.people = res;
            });

            const properties = await TagService.getProperties(props.match.params.listID, idToken);

            if (properties === "NO ACCESS") {
                store.noAccess = true;
                return;
            }

            propertyStore.primaryColor = properties.primaryColor;
            propertyStore.secondaryColor = properties.secondaryColor;

            store.properties = properties;
            store.loaded = true;

        });
    }, []);

    const handleRemovePerson = person => {
        store.openDialog = "revoke";
        store.personToRevokeAccessFrom = person;
    };

    const handleRemoveResult = personId => {
        store.people = store.people.filter(it => it.id !== personId);
    };

    if (store.noAccess) {
        return <NoAccessPage type="not-the-owner" listID={props.match.params.listID}/>
    }

    return (
        <>
            <div className="ls-header"
                 style={{backgroundColor: propertyStore.primaryColor || "#f3f3f3"}}>
                <div style={{display: 'contents'}} className="hamburger-icon-div">
                    <FontAwesomeIcon icon="angle-left" color={"white"} size="lg"
                                     onClick={() => props.history.push(`/${props.match.params.listID}`)}/>
                    <div className="ls-header-title user-select-none"
                         style={!store.loaded ? {color: "white"} : null}>{store.loaded ? `Manage access for ${store.properties.title}` : "Loading access page"}</div>
                </div>
            </div>

            <div
                className={store.openDialog === null ? "dialog-background display-none" : "dialog-background high-z-index"}
                onClick={() => store.openDialog = null}/>

            <MenuDialog resetOpenDialog={() => {store.openDialog = null; store.personToRevokeAccessFrom = null}} openDialog={store.openDialog} person={store.personToRevokeAccessFrom} callback={handleRemoveResult}/>

            <div className="tag-page" style={{paddingTop: "52px"}}>
                <div className="tag-explanation">
                    {store.noSecurity ? <>
                        <h1>This list is public</h1>
                        <p>If you don't want everyone to be able to access this list, you can change the settings
                            below.</p>
                    </> : <>
                        <h1>Manage list access</h1>
                        <p>Revoke or grant access to your list. Once you have removed someone, they can’t access or edit
                            this list anymore. If you change your mind, they can always be re-invited!</p>
                    </>}
                </div>
                <Sharks/>
                <div className="page-content">
                    {store.people.map(person => <div className="person-container" key={person.id}>
                        <div className="person" style={person.id === store.userID ? {
                            backgroundColor: propertyStore.primaryColor,
                            color: "white",
                            fontWeight: "bold"
                        } : null}>{person.name + (person.id === store.userID ? " (you)" : "")}</div>
                        {person.id === store.userID ? null :
                            <div className="cross" onClick={() => handleRemovePerson(person)}><FontAwesomeIcon
                                icon="times"/></div>}
                    </div>)}
                </div>
            </div>
        </>
    )
});

