import React, {Component} from "react";

export default class Sharks extends Component {
    render() {
        return (
            <div className="ls-sharks">
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
                <div className="shark"/>
            </div>
        )
    }
};

