import React, {useRef, useEffect} from "react";

import {observer, useLocalStore} from "mobx-react-lite";
import {useStyleStore} from "../../stores/PropertyStore";
import ListService from "../../services/ListService";

export const ListItemEditMode = observer(props => {
    const styleStore = useStyleStore();
    const editInput = useRef(null);

    useEffect(() => {
        editInput.current.focus();
    });

    const store = useLocalStore(() => ({
        tempItemEdit: null,
    }));

    const handleEditItemSubmit = e => {
        if (e.key !== "Enter") return;
        // No changes made
        if (store.tempItemEdit === null) {
            return;
        }

        const theNewItem = store.tempItemEdit;

        ListService.editItem(styleStore.id, theNewItem, styleStore.idToken).then(() => {
            store.tempItemEdit = null;

            props.disableEditMode(theNewItem);
        }).catch(() => {
            console.error("Error updating item: ", theNewItem);
        });
    };

    const setTagForItem = (tag, item) => {
        item.tag = tag === item.tag ? null : tag;
        store.tempItemEdit = item;
        editInput.current.focus();
    };

    return (
        <div className="item-edit-superior" style={{flex: 1}}>
            <div className="item-edit-container">
                <input type="text"
                       defaultValue={props.item.item}
                       onKeyUp={handleEditItemSubmit}
                       onChange={e => {
                           const copy = props.item;
                           copy.item = e.target.value;
                           store.tempItemEdit = copy;
                       }}
                       ref={editInput}
                />
                <div className="edit-cancel" onClick={() => props.setEditMode(props.item)}>cancel</div>
            </div>
            <div className="edit-tags-container">
                {styleStore.tags.map(it =>
                    <div key={it}
                         className={store.tempItemEdit === null ? props.item.tag === it ? "ls-fab tag-selected" : "ls-fab no-shadow" : store.tempItemEdit.tag === it ? "ls-fab tag-selected" : "ls-fab no-shadow"}
                         onClick={() => setTagForItem(it, props.item)}>{it}
                    </div>
                )}
            </div>
        </div>
    )
});

