import React, {useEffect} from "react";

import {observer, useLocalStore} from "mobx-react-lite";
import {useStyleStore} from "../../stores/PropertyStore";
import firebase from "firebase/app";
import "firebase/auth";
import StorageService from "../storage/StorageService";
import MenuGestureListener from "./MenuGestureListener";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import TableSection from "./TableSection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {MenuDialog} from "./MenuDialogs";
import ListService from "../../services/ListService";
import "./HamburgerMenu.scss";

export const HamburgerMenuHooks = observer(props => {
    const styleStore = useStyleStore();
    let unregisterAuthObserver;

    const store = useLocalStore(() => ({
        openDialog: null,
        colorSelectOpen: false,
        menuX: null,
        menuOpen: false,
        savedListPressedTimer: null,
        isOwner: false
    }));

    useEffect(() => {
        unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            async (user) => {
                let idToken = null;

                if (user) {
                    idToken = await user.getIdToken();
                }

                try {
                    const lists = await StorageService.getLists(styleStore.id, idToken);
                    styleStore.savedLists = lists.map(listID => ({selected: false, listID}));
                } catch (err) {
                    console.error("Couldn't get saved lists: ", err);
                }

                try {
                    const uids = await ListService.getUserAccess(styleStore.id, idToken);
                    if (uids && uids !== "NO ACCESS") store.isOwner = true;
                } catch (err) {

                }
            }
        );

        return () => {
            unregisterAuthObserver();
        };
    }, []);

    const savedLists = styleStore.savedLists.map(list => {
        return <div className="saved-list-flex"
                    key={list.listID}>
            <div className="saved-list"
                 style={{
                     backgroundColor: list.listID === styleStore.id ? styleStore.primaryColor : styleStore.darkModeOn ? null : "#D9D9D9",
                     color: list.listID === styleStore.id ? "white" : styleStore.darkModeOn ? null : "black"
                 }}
                 onTouchStart={() => handleSavedListPress(list.listID)}
                 onTouchEnd={() => handleSavedListRelease(list.listID)}
                 onMouseDown={() => handleSavedListPress(list.listID)}
                 onMouseUp={() => handleSavedListRelease(list.listID)}
            >
                {list.listID}
            </div>
            {list.selected ?
                <div className="saved-list-select" style={{color: styleStore.primaryColor}}><FontAwesomeIcon
                    icon="check"/></div> : null}
        </div>
    });

    // Saved lists
    const handleSavedListPress = listID => {
        if (styleStore.id === listID) return;

        if (store.savedListPressedTimer === null || store.savedListPressedTimer === 'invalidated') {
            store.savedListPressedTimer = "set";
            setTimeout(() => {
                styleStore.savedLists = styleStore.savedLists.map(list => {
                    if (list.listID === listID) {
                        list.selected = !list.selected;
                    }
                    return list;
                });
                store.savedListPressedTimer = 'invalidated';
            }, 500);
        }
    };

    const handleSavedListRelease = listID => {
        // Timer is not running anymore
        if (store.savedListPressedTimer === 'invalidated') return;

        // Go to the page
        if (styleStore.id !== listID)
            window.location.href = `/${listID}`;

        clearTimeout(store.savedListPressedTimer);
    };

    const handleUnfavoriteClick = () => {
        styleStore.savedLists = styleStore.savedLists.filter(it => !it.selected);
        StorageService.setNewLists(styleStore.savedLists.map(it => it.listID), styleStore.idToken);
    };

    return (
        <div>
            <MenuGestureListener onMenuDrag={x => store.menuX = x}
                                 openMenu={() => {
                                     props.changeMenuStateToOpen(true);
                                     store.menuX = null;
                                 }}
                                 closeMenu={() => {
                                     props.changeMenuStateToOpen(false);
                                     store.menuX = null;
                                 }}
            />
            <div
                className={store.openDialog === null ? "dialog-background display-none" : "dialog-background high-z-index"}
                onClick={() => store.openDialog = null}/>

            <MenuDialog resetOpenDialog={() => store.openDialog = null} openDialog={store.openDialog}/>
            <div className={props.open ? "ls-hamburger hamburger-open" : "ls-hamburger"}
                 style={store.menuX === null ? null : {left: `calc(-100% + 135px + ${store.menuX}px`}}>
                <TableSection title="Lists">
                    <div className="saved-lists">
                        {savedLists}
                        <div className="saved-list-flex" onClick={() => store.openDialog = "newList"}>
                            <div className="saved-list add-list" style={styleStore.darkModeOn ? null : {
                                boxShadow: `inset 0px 0px 0px 3px ${styleStore.primaryColor}`,
                                color: styleStore.primaryColor
                            }}>
                                +
                            </div>
                        </div>
                    </div>
                </TableSection>
                <TransitionGroup>
                    {styleStore.savedLists.filter(it => it.selected).length > 0 ?
                        <CSSTransition
                            timeout={1100}
                            classNames="menu-slide">
                            <div className="selected-list-options">
                                <div className="menu-selected-list-options" onClick={handleUnfavoriteClick}>
                                    <FontAwesomeIcon icon="star"
                                                     color={styleStore.primaryColor}/> Unfavorite
                                </div>
                            </div>
                        </CSSTransition> : null}
                </TransitionGroup>
                <TableSection title="Share">
                    <div className="row first">
                        <a href={`https://wa.me/?text=I've%20invited%20you%20to%20my%20list!%20Join%20me%20here:%20${window.location.href}`}
                           target="_blank"
                           rel="noopener noreferrer" style={{width: '100%'}}>
                            <FontAwesomeIcon icon="share" style={{color: styleStore.primaryColor}}/> Share this
                            list!
                        </a>
                    </div>
                </TableSection>
                <TableSection title="Settings">

                    {/* styleStore.isPrivate && styleStore.listOwnerId === styleStore.currentUser.id */}
                    {store.isOwner ?
                        <div className="row first"><Link to={`${props.url}/access`} style={{width: '100%'}}>Manage list
                            access</Link></div> : null}

                    <div
                        className={store.colorSelectOpen ? "row with-icon font-semi-bold" : "row with-icon"}
                        onClick={() => store.colorSelectOpen = !store.colorSelectOpen}>
                        Change colors
                        <FontAwesomeIcon icon="angle-down"/>
                    </div>
                    <TransitionGroup>
                        {store.colorSelectOpen ?
                            <CSSTransition classNames="fade-menu" timeout={1000}>
                                <div style={{overflow: "hidden"}}>
                                    <div className="row row-secondary"
                                         onClick={() => store.openDialog = "primaryColor"}>Change
                                        primary color
                                    </div>
                                    <div className="row row-secondary"
                                         onClick={() => store.openDialog = "secondaryColor"}>Change
                                        secondary color
                                    </div>
                                    <div className="row row-secondary"
                                         onClick={() => store.openDialog = "resetColor"}>
                                        Reset to default
                                    </div>
                                </div>
                            </CSSTransition> : null}
                    </TransitionGroup>

                    <div className="row" onClick={() => store.openDialog = "title"}>Change title</div>
                    <div className="row"><Link to={`${props.url}/tags`} style={{width: '100%'}}>Tags</Link></div>
                    <div className="row">
                        <Link to={`${props.url}/history`} style={{width: '100%'}}>
                            History
                        </Link>
                    </div>
                    <div className="row" onClick={() => styleStore.darkModeOn = !styleStore.darkModeOn}>Switch
                        to {styleStore.darkModeOn ? "light mode" : "dark mode"}</div>
                    <div className="row"><Link to={`${props.url}/stats`} style={{width: '100%'}}>Stats</Link></div>
                </TableSection>
                <TableSection title="Account">
                    {styleStore.signedIn ?
                        <div>
                            <div className="row with-icon first italic">
                                Hello {firebase.auth().currentUser.displayName}!
                            </div>
                            <div className="row with-icon" onClick={() => firebase.auth().signOut()}>
                                Sign out
                                <FontAwesomeIcon icon="angle-right"/>
                            </div>
                        </div> :
                        <div>
                            <Link to={styleStore.id + "/sign-in"}>
                                <div className="row with-icon first">
                                    Signup/Login
                                    <FontAwesomeIcon icon="angle-right"/>
                                </div>
                            </Link>
                        </div>
                    }
                </TableSection>
            </div>
        </div>
    )
});

