import firebase from 'firebase/app';
import "firebase/auth";

// Configure Firebase.
if (!firebase.apps.length) {
    const config = {
        apiKey: 'AIzaSyDJ1J97b2DX0joozyRtKZ1Vkj0_ESHOR84',
        authDomain: 'listshare1.firebaseapp.com',
    };
    firebase.initializeApp(config);
}

class FirebaseService {
    idToken = null;
    uid = null;

    constructor() {
        this.getIdToken(() => null);
    }

    getIdToken = cb => {
        if (this.idToken !== null) return cb(this.idToken, this.uid);

        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            async (user) => {

                if (!user) {
                    return cb(null);
                }

                try {
                    const user = firebase.auth().currentUser;
                    this.idToken = await user.getIdToken(true);
                    this.uid = user.uid;
                    cb(this.idToken, user.uid);
                    // Refresh ID token after 55 minutes
                    setInterval(async () => {
                        if (firebase.auth().currentUser)
                            this.idToken = await firebase.auth().currentUser.getIdToken(true);
                    }, 60 * 55);

                } catch (err) {
                    cb(null);
                    console.error("Couldn't fetch ID token even though user is logged in.");
                }
            }
        );
    };
}

export default new FirebaseService();
