import io from 'socket.io-client';
import env from '../env';

class ListService {
    constructor() {
        const PRODUCTION = env.PRODUCTION;

        if (PRODUCTION) {
            this.socket = io.connect(env.BASE_URL, {secure: true, transports: ['websocket']});
            this.baseURL = ""
        } else {
            this.socket = io.connect(env.BASE_URL);
            this.baseURL = env.BASE_URL;
        }
    }

    registerCallbacks(id,
                      // Callbacks
                      newItem, deletedItems, propertiesChanged, itemEdited) {
        this.socket.on('connect', () => {
            this.socket.emit('room', id);
        });

        this.socket.on('itemToClient', (socketIDToExclude, item) => {
            if (item.item === undefined || this.socket.id === socketIDToExclude)
                return;

            newItem(item);
        });

        this.socket.on('editToClient', (socketIDToExclude, item) => {
            if (item.item === undefined || item.item === null || this.socket.id === socketIDToExclude)
                return;

            itemEdited(item);
        });

        this.socket.on('deleteToClient', (socketIDToExclude, ids) => {
            if (ids === undefined)
                console.log("empty delete gotten");

            if (this.socket.id === socketIDToExclude) return;

            deletedItems(ids);
        });

        this.socket.on('changePropertiesToClient', (socketIDToExclude, properties) => {
            if (properties === undefined || properties === null)
                console.log("empty properties gotten");

            if (this.socket.id === socketIDToExclude) return;

            propertiesChanged(properties)
        });
    }

    setupSocketAccess = idToken => {
        this.socket.emit("access", idToken);
    };

    async insertItem(id, name, tag, idToken) {
        const response = await fetch(`${this.baseURL}/v2/list/${id}/items`, {
            method: "POST",
            headers: {
                "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                "Content-Type": "application/json; charset=utf-8",
            },
            referrer: "no-referrer",
            body: JSON.stringify({
                item: name,
                tag: tag === null ? "null" : tag,
                socketID: this.socket.id
            }),
        });

        const json = await response.json();

        if (json.insertedID === undefined) {
            return 'anErrorCodeWhichWillNeverOccurEverInTheHistoryOfTheUniverse'
        }

        return json.insertedID;
    }

    async deleteItems(id, itemIDs, idToken) {
        const response = await fetch(`${this.baseURL}/v2/list/${id}/items`, {
            method: "DELETE",
            headers: {
                "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                "Content-Type": "application/json; charset=utf-8",
            },
            referrer: "no-referrer",
            body: JSON.stringify({
                items: itemIDs,
                socketID: this.socket.id
            }),
        });

        switch(response.status) {
            case 200:
                break;
            default:
                return 'error'
        }
    }

    async editItem(id, item, idToken) {
        const strippedItem = {
            _id: item._id,
            item: item.item,
            tag: item.tag
        };
        const response = await fetch(`${this.baseURL}/v2/list/${id}/items`, {
            method: "PATCH",
            headers: {
                "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                "Content-Type": "application/json; charset=utf-8",
            },
            referrer: "no-referrer",
            body: JSON.stringify({
                item: strippedItem,
                socketID: this.socket.id
            }),
        });


        switch(response.status) {
            case 200:
                break;
            default:
                return 'error'
        }
    }

    async updateProperties(id, properties, idToken) {
        const response = await fetch(`${this.baseURL}/v2/list/${id}`, {
            method: "PATCH",
            headers: {
                "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                "Content-Type": "application/json; charset=utf-8",
            },
            referrer: "no-referrer",
            body: JSON.stringify({
                properties,
                socketID: this.socket.id
            }),
        });

        switch(response.status) {
            case 200:
                break;
            default:
                return 'error'
        }
    }

    async getList(id, idToken) {
        const response = await fetch(`${this.baseURL}/v2/list/get/${id}`, {
            method: "GET",
            headers: {
                "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                "Content-Type": "application/json; charset=utf-8",
            },
            referrer: "no-referrer",
        });

        switch(response.status) {
            case 200:
                const json = await response.json();

                if (json.properties === undefined) {
                    json.properties = {}
                }

                if (json.properties.primaryColor === undefined) {
                    json.properties.primaryColor = '#6523F3';
                }

                if (json.properties.secondaryColor === undefined) {
                    json.properties.secondaryColor = '#E5DCF9';
                }

                if (json.properties.title === undefined) {
                    json.properties.title = id;
                }

                if (json.properties.tags === undefined) {
                    json.properties.tags = ["🍎", "🥦", "🥐" , "🧀" , "🍗" , "🍬" , "🍪" , "🍷" ];
                }

                if (json.items === undefined) {
                    json.items = [];
                }
                return json;
            case 401:
                const jsonForbidden = await response.json();
                return jsonForbidden.reason;
            default:
                return 'error';
        }
    }

    async createList(id, idToken, inviteOnly) {
        const response = await fetch(`${this.baseURL}/v2/list/${id}`, {
            method: "POST",
            headers: {
                "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                "Content-Type": "application/json; charset=utf-8",
            },
            referrer: "no-referrer",
            body: JSON.stringify({
                id,
                isUUID: false,
                inviteOnly: inviteOnly,
            }),
        });

        switch(response.status) {
            case 200:
                return response;
            case 409:
                return 'duplicate';
            default:
                return 'error'
        }
    }

    // Security
    async getUserAccess(listID, idToken) {
        try {
            const response = await fetch(`${this.baseURL}/v2/list/${listID}/security/access`, {
                method: "GET",
                headers: {
                    "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                    "Content-Type": "application/json; charset=utf-8",
                },
                referrer: "no-referrer",
            });

            if (response.status === 401) return "NO ACCESS";

            if (!response.ok) return null;

            return await response.json()
        } catch (e) {
            console.error(e);
            return null
        }
    }


    // Access
    async revokeAccess(listID, personID, idToken) {
        try {
            const response = await fetch(`${this.baseURL}/v2/list/${listID}/security/access/${personID}`, {
                method: "DELETE",
                headers: {
                    "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                    "Content-Type": "application/json; charset=utf-8",
                },
                referrer: "no-referrer",
            });

            if (response.status === 401) return "NO ACCESS";

            if (!response.ok) return null;

            return await response.json();
        } catch (err) {
            console.error(err);
            return 'error'
        }
    }


    // Stats
    async getContributions(listID, idToken) {
        try {
            const response = await fetch(`${this.baseURL}/v2/stats/${listID}/contributions`, {
                method: "GET",
                headers: {
                    "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                    "Content-Type": "application/json; charset=utf-8",
                },
                referrer: "no-referrer",
            });

            const json = await response.json();

            switch (response.status) {
                case 200:
                    return json;
                case 401:
                    return json.error;
                default:
                    return 'error';
            }
        } catch (err) {
            console.error(err);
            return 'error'
        }
    }
}

export default new ListService();
