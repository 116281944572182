import env from '../../env';

class TagService {
    constructor() {
        const PRODUCTION = env.PRODUCTION;

        if (PRODUCTION) {
            this.baseURL = ""
        } else {
            this.baseURL = env.BASE_URL;
        }
    }

    async getProperties(id, idToken) {
        const response = await fetch(`${this.baseURL}/v2/list/properties/${id}`, {
            method: "GET",
            headers: {
                "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                "Content-Type": "application/json; charset=utf-8",
            },
            referrer: "no-referrer",
        });

        switch(response.status) {
            case 200:
                try {
                    const json = await response.json();

                    if (json.primaryColor === undefined) {
                        json.primaryColor = '#6523F3';
                    }

                    if (json.secondaryColor === undefined) {
                        json.secondaryColor = '#E5DCF9';
                    }

                    if (json.title === undefined) {
                        json.title = id;
                    }

                    if (json.tags === undefined) {
                        json.tags = ["🍎", "🥦", "🥐", "🧀", "🍗", "🍬", "🍪", "🍷"]
                    }

                    return json;
                } catch (err) {
                    return 'error';
                }
            case 401:
                const json = await response.json();
                return json.error;
            default:
                return 'error';
        }
    };
}

export default new TagService();
