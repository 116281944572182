import React from 'react'
import {useDisposable, useLocalStore} from 'mobx-react-lite'
import {autorun} from "mobx";
import StorageService from "../features/storage/StorageService";
import FirebaseService from "../services/FirebaseService";

function createStore() {
    const store = {
        id: null,
        primaryColor: "#6523F3",
        secondaryColor: "#E5DCF9",
        darkModeOn: StorageService.getDarkMode(),
        tags: ["🍎", "🥦", "🥐" , "🧀" , "🍗" , "🍬" , "🍪" , "🍷" ],
        title: null,
        // Move to other store later
        idToken: null,
        savedLists: [],
        signedIn: false,
        user: null,
    };

    return store
}

const storeContext = React.createContext(null);

export const StyleStoreProvider = ({children}) => {
    const store = useLocalStore(createStore);

    useDisposable(() =>
        autorun(() => {
            const metaThemeColor = document.querySelector("meta[name=theme-color]");
            metaThemeColor.setAttribute("content", store.darkModeOn ? "#2f2f2f" : store.primaryColor);
            document.body.style.backgroundColor = store.darkModeOn ? "#3F3F3F" : null;
            StorageService.setDarkMode(store.darkModeOn);
        }),
        autorun(() => {
            FirebaseService.idToken = store.idToken;
        })
    );

    return <storeContext.Provider value={store}>{children}</storeContext.Provider>
};

export const useStyleStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        throw new Error('You have forgot to use StoreProvider, shame on you.')
    }
    return store
};


