import env from '../env';

class UserDataService {
    constructor() {
        const PRODUCTION = env.PRODUCTION;

        if (PRODUCTION) {
            this.baseURL = ""
        } else {
            this.baseURL = env.BASE_URL;
        }
    }

    async updateProperties(idToken, properties) {

        const response = await fetch(`${this.baseURL}/v2/user/properties`, {
            method: "POST",
            headers: {
                "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                "Content-Type": "application/json; charset=utf-8",
            },
            referrer: "no-referrer",
            body: JSON.stringify({
                properties,
            }),
        });

        return await response.json();
    }

    async getProperties(idToken) {
        const response = await fetch(`${this.baseURL}/v2/user`, {
            method: "GET",
            headers: {
                "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                "Content-Type": "application/json; charset=utf-8",
            },
            referrer: "no-referrer"
        });

        return await response.json();
    }

}

export default new UserDataService();
