import React from 'react';
import './LSSwitch.css';

class LSSwitch extends React.Component {
    state = {
        checked: false,
    };

    handleChange = () => {
        const newState = !this.state.checked;
        this.setState({checked: newState});
        this.props.handleChecked(newState);
    };

    render() {
        return (
            <>
                <input type="checkbox" id="cbx" style={{display: "none"}}/>
                <label htmlFor="cbx" className="toggle" onClick={() => this.handleChange()}>
                    <span style={{background: this.state.checked ? this.props.primaryColor : "#FFF"}}/>
                </label>
            </>
        );
    }
}


export default LSSwitch;
