import UserDataService from "../../services/UserDataService";

class StorageService {
    async saveList(listID, idToken) {
        if (!this.storageAvailable()) return null;


        let savedLists = await this.getLists();
        if (savedLists === null) savedLists = [];

        if (!savedLists.includes(listID)) {
            savedLists.push(listID);
            this.setNewLists(savedLists, idToken)
        }
    }

    // For PWA:
    saveLastOpenedList(listID) {
        if (!this.storageAvailable()) return;

        localStorage.setItem("lastOpened", listID);
    }

    getLastOpenedList() {
        if (!this.storageAvailable()) return null;

        return localStorage.getItem("lastOpened");
    }

    setDarkMode(darkMode) {
        if (!this.storageAvailable()) return;

        localStorage.setItem('darkMode', darkMode);
    }

    getDarkMode() {
        if (!this.storageAvailable()) return false;

        return localStorage.getItem('darkMode') === "true";
    }

    async getLists(forID, idToken) {
        if (!this.storageAvailable()) {
            console.error("Couldn't fetch cached lists: storage not available.");

            if (idToken === null || idToken === undefined) {
                return [];
            }

            return await this.getListsFromUser(idToken);
        }

        // Cloud saved
        if (idToken) {
            const cloudSaved = await this.getListsFromUser(idToken);
            if ((forID !== null || forID !== undefined) && !cloudSaved.includes(forID)) {
                cloudSaved.push(forID)
            }
            return cloudSaved
        }

        // Locally saved
        return this._getLocalStorageLists(forID);
    }

    sameElements(array1, array2) {
        return array1.length === array2.length && array1.sort().every(function(value, index) { return value === array2.sort()[index]});
    }

    _getLocalStorageLists(forID) {
        const listsInStorage = localStorage.getItem('lists');

        if (listsInStorage === undefined || listsInStorage === null) {
            return forID === null || forID === undefined ? [] : [forID];
        }

        let lists = JSON.parse(localStorage.getItem('lists'));
        if (forID !== null && forID !== undefined && !lists.includes(forID)) lists.push(forID);

        if (lists === undefined || lists === null || lists === [])
            lists = [forID];

        return lists;
    };

    async getListsFromUser(idToken) {
        try {
            const props = await UserDataService.getProperties(idToken);
            if (props !== null && props.hasOwnProperty("storedLists"))
                return props.storedLists;
            else
                return [];
        } catch (err) {
            console.error("Failed to retrieve lists from user. ", err);
            return [];
        }
    }

    setNewLists(lists, idToken) {
        if (idToken !== null && idToken !== undefined)
            UserDataService.updateProperties(idToken, {storedLists: lists});

        if (!this.storageAvailable()) return null;

        localStorage.setItem('lists', JSON.stringify(lists));
    }

    storageAvailable() {
        const storage = window['localStorage'],
            x = '__storage_test__';
        try {

            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch (e) {
            return e instanceof DOMException && (
                    // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0;
        }
    }

}

export default new StorageService();
