import React, {Component} from 'react';

export default class MenuGestureListener extends Component {
    state = {isDragging: false};

    handleTouchStart = event => {
        const clientX = event.changedTouches[0].clientX;
        const clientY = event.changedTouches[0].clientY;
        this.setState({isDragging: true, initialClientX: clientX});
        console.log(clientX, clientY);
    };

    handleTouchMove = event => {
        const clientX = event.changedTouches[0].clientX;
        this.props.onMenuDrag(clientX);
    };

    handleTouchEnd = event => {
        const clientX = event.changedTouches[0].clientX;

        if (clientX - this.state.initialClientX > 35) {
            this.props.openMenu();
        } else {
            this.props.closeMenu();
        }
        this.setState({isDragging: false});

    };

    render() {
        return(
            <div className="menu-gesture-box"
                 onTouchStart={this.handleTouchStart}
                 onTouchMove={this.handleTouchMove}
                 onTouchEnd={this.handleTouchEnd}
            />
        )
    }
}