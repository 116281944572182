import React, {Component} from 'react';
import './App.css';
import LandingPage from "./features/landing/LandingPage";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'
import firebase from 'firebase/app';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faUsers,
    faMobile,
    faRocket,
    faBars,
    faAngleDown,
    faAngleRight,
    faSpinner,
    faArrowAltCircleUp,
    faAngleUp,
    faClock,
    faHistory,
    faTag,
    faTrash,
    faTimes,
    faCheck,
    faStar,
    faTrophy,
    faShare,
    faAngleLeft, faFighterJet
} from '@fortawesome/free-solid-svg-icons'
import TagPage from "./features/tags/TagPage";
import RegisterPage from "./features/accounts/RegisterPage";
import StatsPage from "./features/stats/StatsPage";
import {StyleStoreProvider} from "./stores/PropertyStore";
import {ListPageHookz} from "./features/list/ListPageHooks";
import {HistoryPageHooks} from "./features/history/HistoryPageHooks";
import {ListAccessPage} from "./features/access/ListAccessPage";

library.add(faUsers, faMobile, faRocket, faBars, faAngleDown, faAngleRight, faSpinner, faArrowAltCircleUp, faAngleUp, faClock,
    faHistory, faTag, faTrash, faCheck, faStar, faAngleLeft, faShare, faTrophy, faTimes, faFighterJet);


// Configure Firebase.
if (!firebase.apps.length) {
    const config = {
        apiKey: 'AIzaSyDJ1J97b2DX0joozyRtKZ1Vkj0_ESHOR84',
        authDomain: 'listshare1.firebaseapp.com',
    };
    firebase.initializeApp(config);
}

class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <StyleStoreProvider>
                        <Route exact path="/" component={LandingPage}/>
                        <Switch>
                            <Route path="/:listID/sign-in" component={RegisterPage}/>
                            <Route path="/:listID/tags" component={TagPage}/>
                            <Route path="/:listID/stats" component={StatsPage}/>
                            <Route path="/:listID/access" component={props => <ListAccessPage {...props}/>}/>
                            <Route path="/:listID/history" component={props => <HistoryPageHooks {...props} />}/>
                            <Route path="/:listID" render={props => <ListPageHookz {...props} />}/>
                        </Switch>
                    </StyleStoreProvider>
                </div>
            </Router>
        );
    }
}

export default App;
