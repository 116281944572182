import React, {useEffect} from "react";
import {observer, useLocalStore} from "mobx-react-lite";
import {useStyleStore} from "../../stores/PropertyStore";
import ListService from "../../services/ListService";
import StorageService from "../storage/StorageService";
import firebase from "firebase/app";
import "firebase/auth";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import NoAccessPage from "../../components/NoAccessPage";
import {insertLinksInText} from "../../utils/linksInText";
import {ListInput} from "./ListInput";
import {ListItemEditMode} from "./ListItemEditMode";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Sharks from "../../components/Sharks";
import {HamburgerMenuHooks} from "../menu/HamburgerMenuHooks";
import "./ListPage.scss";

// TODO: Missing functionalities
// List items out of sight
// Refresh idToken, maybe refresh in the store somewhere
export const ListPageHookz = observer(props => {
    const styleStore = useStyleStore();
    let unregisterAuthObserver;

    // Local state
    const store = useLocalStore(() => ({
        loading: true,
        hamburgerOpen: false,
        items: [],
        notVisibleItems: [],
        listRequestResult: null,
        justSelected: false,
        timers: {}
    }));

    useEffect(() => {
        console.log("Hello there!");
        styleStore.id = styleStore.id || props.match.params.listID;
        document.title = `${styleStore.title || props.match.params.listID} – Listshare`;

        // Login
        unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            async user => {
                styleStore.signedIn = !!user;

                if (!user) {
                    styleStore.idToken = null;
                    _setList();
                    StorageService.saveList(styleStore.id);
                    return;
                }

                try {
                    const idToken = await firebase.auth().currentUser.getIdToken(true);
                    styleStore.idToken = idToken;

                    if (store.listRequestResult !== "no-access") {
                        _setList();
                    }

                    ListService.setupSocketAccess(idToken);
                    StorageService.saveList(styleStore.id, idToken);

                } catch (err) {
                    console.error("Couldn't fetch ID token even though user is logged in.");
                }
            }
        );

        document.addEventListener('visibilitychange', () => {
            if (!document.hidden) _setList();
        });

        console.log("Register callbacks", styleStore.id);
        ListService.registerCallbacks(styleStore.id,
            newItem,
            deletedItems,
            newProperties,
            itemEdited,
        );

        return () => {
            // Cleanup
            unregisterAuthObserver();
        };
    }, []);

    const _setList = () => {
        ListService.getList(styleStore.id, styleStore.idToken).then(value => {
            if (value === "no-access-without-user-id") {
                store.listRequestResult = "no-access-without-login";
                return;
            }

            if (value === "no-access-with-user-id") {
                store.listRequestResult = "no-access";
                return;
            }

            if (value === "error") {
                store.listRequestResult = "error";
                return;
            }

            StorageService.saveLastOpenedList(styleStore.id.toLowerCase());
            styleStore.title = value.properties.title;
            styleStore.primaryColor = value.properties.primaryColor;
            styleStore.secondaryColor = value.properties.secondaryColor;
            styleStore.tags = value.properties.tags;
            store.items = value.items.map(item => {
                const itemIDs = store.items.map(i => i._id);
                if (itemIDs.includes(item._id)) {
                    const theItem = store.items[itemIDs.indexOf(item._id)];
                    if (theItem !== null && theItem !== undefined) {
                        item.selected = theItem.selected
                    }
                }
                return item;
            });
            store.listRequestResult = "loaded";
            store.loading = false;

            document.title = `${styleStore.title || props.match.params.listID} – Listshare`;
        });
    };

    // Socket callbacks
    const newItem = item => {
        store.items.push(item);
    };

    const deletedItems = itemIDs => {
        store.items = store.items.filter(it => !itemIDs.includes(it._id))
    };

    const newProperties = properties => {
        styleStore.title = properties.title;
        styleStore.primaryColor = properties.primaryColor;
        styleStore.secondaryColor = properties.secondaryColor;
        styleStore.tags = properties.tags;
    };

    const itemEdited = item => {
        const indexOfItem = store.items.map(it => it._id).indexOf(item._id);

        if (indexOfItem < 0) return;
        item.justEdited = true;
        store.items[indexOfItem] = item;

        // remove just edited from this item after animation finishes
        setTimeout(() => {
            const indexOfItem = store.items.map(it => it._id).indexOf(item._id);

            if (indexOfItem < 0) return;
            item.justEdited = false;
            store.items[indexOfItem] = item;

        }, 300);
    };

    // Other
    const handleHamburgerClick = () => {
        store.hamburgerOpen = !store.hamburgerOpen;
    };

    const selectListItem = item => {
        store.items = store.items.map(it => {
            it.editMode = false;
            if (it === item) it.selected = !it.selected;
            return it;
        });
        store.justSelected = true;

        setTimeout(() => {
            store.justSelected = false;
        }, 300)
    };

    const handleItemPress = item => {
        const timeout = store.timers[`timer-${item._id}`];

        if (timeout !== undefined) {
            clearTimeout(timeout);
            delete store.timers[`timer-${item._id}`];
        }

        store.timers[`timer-${item._id}`] = setTimeout(() => {
            delete store.timers[`timer-${item._id}`];
            // Copy old item
            store.itemBeforeEdit = {...item};

            store.items = store.items.map(it => {
                it.editMode = false;

                if (it === item) {
                    it.editMode = true;
                    it.selected = false;
                }

                return it;
            });

            // this.editInput.focus(); //TODO: [Hooks] Make this work once items are also in function component
        }, 1500);
    };

    const handleItemRelease = item => {
        clearTimeout(store.timers[`timer-${item._id}`]);
        delete store.timers[`timer-${item._id}`];
    };

    const handleDelete = () => {
        const itemsToDelete = store.items.filter(it => it.selected);
        ListService.deleteItems(styleStore.id, itemsToDelete.map(item => item._id), styleStore.idToken).then(result => {
            if (result === "error") {
                // TODO: Handle error
            } else {
                store.items = store.items.filter(it => !itemsToDelete.includes(it));
            }
        });
    };

    const addNewItemLocally = (_id, item, tag) => {
        store.items.push({_id, item, tag});
    };

    // Edit stuff
    const cancelEditMode = item => {
        if (store.itemBeforeEdit !== null) {
            store.items[store.items.indexOf(item)] = store.itemBeforeEdit;
            store.itemBeforeEdit = null;
        }
    };

    const disableEditMode = item => {
        store.items = store.items.map(it => {
            it.editMode = false;
            return it;
        });

        itemEdited(item);
    };
    //
    // // Components
    const deleteButton = <CSSTransition classNames="appear" timeout={600}>
        <div className={store.justSelected && store.items
            .map(i => i.selected)
            .filter(i => i === true)
            .length > 1 ? "delete-container pop" : "delete-container"}>
            <div className="ls-delete no-select" style={{backgroundColor: styleStore.primaryColor}}
                 onClick={handleDelete}>
                Delete {store.items.filter(it => it.selected).length}
                {" "}
                item{store.items.filter(it => it.selected).length === 1 ? null : "s"}
            </div>
        </div>
    </CSSTransition>;

    const listItems = store.items.slice().sort((first, second) => ('' + first.tag).localeCompare(second.tag)).map(item => {
            const id = item._id;
            // this[id] = React.createRef();
            return <CSSTransition
                key={id}
                timeout={500}
                classNames="fade">
                <div
                    className={item.justEdited ? "pop item" : "item"}
                    key={id}
                    onClick={() => item.editMode ? null : selectListItem(item)}
                    onTouchStart={() => handleItemPress(item)}
                    onTouchEnd={() => handleItemRelease(item)}
                    onTouchMoveCapture={() => handleItemRelease(item)}
                    onMouseDown={() => handleItemPress(item)}
                    onMouseUp={() => handleItemRelease(item)}
                    onMouseLeave={() => handleItemRelease(item)}
                    style={item.selected ? {
                        backgroundColor: styleStore.darkModeOn ? "#565656" : styleStore.secondaryColor,
                        borderColor: styleStore.darkModeOn ? "#606060" : styleStore.secondaryColor
                    } : null}>
                    {item.editMode ?
                        <ListItemEditMode disableEditMode={disableEditMode} setEditMode={cancelEditMode} item={item}/> :
                        <>
                            <div className="name">
                                {insertLinksInText(item.item, styleStore.primaryColor)}
                            </div>
                            <div className="list-item-tag">{item.tag}</div>
                        </>
                    }
                </div>
            </CSSTransition>
        }
    );

    const items = store.loading ?
        <div className="ls-loading">
            <div className="ls-spinner-container">
                <div className="ls-spinner"/>
            </div>
        </div>
        : <div className="ls-list">
            <TransitionGroup className="transition-group">
                {listItems}
            </TransitionGroup>
            <ListInput addNewItemLocally={addNewItemLocally}/>
        </div>;

    if (store.listRequestResult === "no-access" ||
        store.listRequestResult === "no-access-without-login" ||
        store.listRequestResult === "error") {
        return (
            <NoAccessPage type={store.listRequestResult} listID={styleStore.id}/>
        )
    }

    return (
        <div className={styleStore.darkModeOn ? "main dm" : "main"}>
            <HamburgerMenuHooks open={store.hamburgerOpen}
                                id={styleStore.id}
                                url={props.match.url}
                                changeMenuStateToOpen={isOpen => store.hamburgerOpen = isOpen}
                                history={props.history}
            />
            <div className={store.hamburgerOpen ? "fade" : "fade hidden"}
                 onClick={handleHamburgerClick}
            />
            <div className={store.loading ? "ls-header ls-loading-header" : "ls-header"}
                 style={{backgroundColor: store.loading ? '#f3f3f3' : styleStore.primaryColor}}>
                {store.loading ? <div className="ls-header-title user-select-none"
                                      style={store.loading ? {color: "#424242"} : null}>Loading
                        Listshare</div> :
                    <div style={{display: 'contents'}} className="hamburger-icon-div">
                        <FontAwesomeIcon icon="bars" color="white" size="lg"
                                         onClick={handleHamburgerClick}/>
                        <div className="ls-header-title user-select-none">{styleStore.title}</div>
                    </div>
                }
            </div>
            <div className="ls-header-filler"/>

            {items}

            <TransitionGroup>
                {store.items.length === 0 && !store.loading ? <CSSTransition
                    timeout={2100}
                    classNames="slide">
                    <div className="ls-empty">
                        <Sharks/>

                        <div className="container">
                            <FontAwesomeIcon icon="arrow-alt-circle-up" size="4x" color="#DBDBDB"/>
                            <div className="subtitle">
                                Looks like your list is empty,
                                start typing above to add an item.
                            </div>
                        </div>
                    </div>
                </CSSTransition> : null}

                {store.items
                    .map(i => i.selected)
                    .filter(i => i === true)
                    .length > 0 ? deleteButton : null}
            </TransitionGroup>
        </div>
    )
});
