import React, {useEffect} from "react";
import {observer, useLocalStore} from "mobx-react-lite";
import "./HistoryComponent.scss";
import FirebaseService from "../../services/FirebaseService";
import HistoryService from "./HistoryService";
import TagService from "../tags/TagService";
import moment from "moment";
import NoAccessPage from "../../components/NoAccessPage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const HistoryPageHooks = observer(props => {
    const store = useLocalStore(() => ({
        history: [],
        error: null,
        loaded: false,
        properties: {},
        listRequestResult: null
    }));

    useEffect(() => {
        FirebaseService.getIdToken(async idToken => {
            const requestResult = await HistoryService.getHistoryFromLogs(props.match.params.listID, idToken);

            if (requestResult === "NO ACCESS" || requestResult === "error") {
                store.listRequestResult =  requestResult === "NO ACCESS" ? "no-access" : "error";
                return
            }

            let history = [...requestResult]
                .map(historyItem => {
                    if (historyItem.hasOwnProperty("value")) {
                        if (historyItem.value.hasOwnProperty("item")) {
                            if (typeof historyItem.value.item === "string") {
                                return historyItem
                            }
                        }
                    }

                    return null;
                })
                .filter(Boolean);

            const properties = await TagService.getProperties(props.match.params.listID, idToken);

            let error = null;
            if (history === null || history === undefined) {
                error = 1;
                history = [];
            }

            store.history = history;
            store.error = error;
            store.properties = properties;
            store.loaded = true;
        });
    }, []);

    const getTimeOfItem = date => moment(date).from(moment());

    if (store.error) {
        return (
            <div>
                <h4>Damn, looks like something went wrong. Please try again later!</h4>
            </div>
        )
    }

    if (store.listRequestResult === "no-access" || store.listRequestResult === "error") {
        return (
            <NoAccessPage type={store.listRequestResult} listID={props.match.params.listID}/>
        )
    }

    return (
        <>
            <div className="ls-header"
                 style={{backgroundColor: store.loaded ? store.properties.primaryColor : "#f3f3f3"}}>
                <div style={{display: 'contents'}} className="hamburger-icon-div">
                    <FontAwesomeIcon icon="angle-left" color={store.loaded ? "white" : "#424242"} size="lg"
                                     onClick={() => props.history.push(`/${props.match.params.listID}`)}/>
                    <div className="ls-header-title user-select-none"
                         style={!store.loaded ? {color: "#424242"} : null}>{store.loaded ? `History of ${store.properties.title}` : "Loading history"}</div>
                </div>
            </div>
            <div className="history-content">
                <div className="history-items-collective">
                    {store.history.map((it, index) => <div key={it._id}
                                                                className="history-item-container"
                                                                style={{backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white"}}>
                            <div className="history-item-details">{it.value.by === "anonymous" ? "Someone" : it.value.by} {it.event === 'add-item' ? "added " : "deleted "}
                                <span>{it.value.item}</span></div>
                            <div className="history-time"><FontAwesomeIcon icon="clock"
                                                                           color={store.properties.primaryColor}/>
                                {getTimeOfItem(it.triggeredOn)}</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
});

