import React, {useRef} from "react";
import {observer} from "mobx-react-lite";
import {useStyleStore} from "../../stores/PropertyStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CSSTransition, TransitionGroup} from "react-transition-group";

export const TagPicker = observer(props => {
    const styleStore = useStyleStore();
    const tagsRef = useRef(null);

    const handleTagClick = tag => {
        props.focusOnInput();

        if (props.selectedTag === tag) {
            props.store.selectedTag = null;
            return;
        }

        props.store.selectedTag = tag;
        props.store.tagsOpen = false;
    };

    const handleOpenTags = () => {
        setTimeout(() => {
            if (tagsRef.current !== null)
                tagsRef.current.scrollIntoView({behavior: 'smooth'});
        }, 50);

        props.store.tagsOpen = !props.store.tagsOpen;
    };

    if (props.store.inputValue === null || props.store.inputValue === undefined || props.store.inputValue === "")
        return null;

    return (
        <>
            <div
                className={props.store.selectedTag === null ? "tag-container" : "tag-container tag-container-emoji"}>
                <div className={props.store.tagsOpen ? `tag-button tag-button-selected` : `tag-button`}
                     onClick={() => handleOpenTags()}> {props.store.selectedTag !== null ?
                    <div className="selected-tag">{props.store.selectedTag}</div> :
                    <FontAwesomeIcon icon="tag" size="xs"/>}
                </div>
                <TransitionGroup>
                    {props.store.tagsOpen ? <CSSTransition
                            key={12131231}
                            timeout={500}
                            classNames="tags">
                            <div className="tags" ref={tagsRef}>
                                {styleStore.tags.map(it => <div key={it}
                                                                className={props.store.selectedTag === it ? "tag tag-selected" : "tag"}
                                                                onClick={() => handleTagClick(it)}>{props.store.selectedTag === it ?
                                    <FontAwesomeIcon icon="trash"/> : it}</div>)}
                            </div>
                        </CSSTransition>
                        : null}
                </TransitionGroup>
            </div>
        </>
    )
});