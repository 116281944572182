import React, {useRef, useEffect} from "react";

import {observer, useLocalStore} from "mobx-react-lite";
import {useStyleStore} from "../../stores/PropertyStore";
import {ChromePicker} from "react-color";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import ListService from "../../services/ListService";
import {CreateListDialogHooks} from "./CreateListDialogHooks";

export const MenuDialog = observer(props => {
    const styleStore = useStyleStore();
    const titleInput = useRef(null);

    const defaultPrimaryColor = "#6523F3";
    const defaultSecondaryColor = "#E5DCF9";

    const store = useLocalStore(() => ({
        loading: false,
        previousValues: {}
    }));

    useEffect(() => {
        store.previousValues.primaryColor = `${styleStore.primaryColor}`;
        store.previousValues.secondaryColor = `${styleStore.secondaryColor}`;
        store.previousValues.title = `${styleStore.title}`;

        if (props.openDialog === "title") {
            titleInput.current.focus();
        }
    }, [props.openDialog]);

    const closeDialog = () => {
        console.log(store.previousValues);
        styleStore.primaryColor = store.previousValues.primaryColor;
        styleStore.secondaryColor = store.previousValues.secondaryColor;
        styleStore.title = store.previousValues.title;
        store.loading = false;
        props.resetOpenDialog();
    };

    const handleTitleInputChange = event => {
        styleStore.title = event.target.value
    };

    const processPropertyUpdates = resetColors => {
        store.loading = true;

        ListService.updateProperties(styleStore.id,
            {
                primaryColor: resetColors === true ? defaultPrimaryColor : styleStore.primaryColor,
                secondaryColor: resetColors === true ? defaultSecondaryColor : styleStore.secondaryColor,
                title: styleStore.title,
                tags: styleStore.tags
            }, styleStore.idToken).then(res => {
            if (res === "error") {
                // todo: handle error
                return
            }
            if (resetColors === true) {
                styleStore.primaryColor = defaultPrimaryColor;
                styleStore.secondaryColor = defaultSecondaryColor;
            }
            store.loading = false;
            props.resetOpenDialog();
        });
    };

    const revokeAccess = async personID => {
        const res = await ListService.revokeAccess(styleStore.id, personID, styleStore.idToken);
        if (res.result === "success") {
            props.callback(personID)
        }
        store.loading = false;
        props.resetOpenDialog();
    };

    let dialog;
    let title;
    let content;
    switch (props.openDialog) {
        case 'title':
            title = "Change title";
            content =
                dialog = <div className="dialog-content dialog-title">
                    <input ref={titleInput} type="text" value={styleStore.title} onChange={handleTitleInputChange}/>
                    <div className="buttons">
                        <div className="button-1" style={{color: styleStore.primaryColor}}
                             onClick={closeDialog}>CANCEL
                        </div>
                        <div className="button-1" style={{color: styleStore.primaryColor}}
                             onClick={processPropertyUpdates}>SUBMIT
                        </div>
                    </div>
                </div>;
            break;
        case 'primaryColor':
            title = "Change primary color";
            content = <div className="dialog-content">
                <ChromePicker color={styleStore.primaryColor}
                              onChangeComplete={color => styleStore.primaryColor = color.hex}/>
                <div className="color-change-preview"
                     style={{backgroundColor: styleStore.primaryColor}}/>
                <div className="buttons">
                    <div className="button-1" style={{color: styleStore.primaryColor}}
                         onClick={closeDialog}>CANCEL
                    </div>
                    <div className="button-1" style={{color: styleStore.primaryColor}}
                         onClick={processPropertyUpdates}>SUBMIT
                    </div>
                </div>
            </div>;
            break;
        case 'secondaryColor':
            title = "Change secondary color";
            content = <div className="dialog-content">
                <ChromePicker color={styleStore.secondaryColor}
                              onChangeComplete={color => styleStore.secondaryColor = color.hex}/>
                <div className="color-change-preview"
                     style={{backgroundColor: styleStore.secondaryColor}}/>
                <div className="buttons">
                    <div className="button-1" style={{color: styleStore.primaryColor}}
                         onClick={closeDialog}>CANCEL
                    </div>
                    <div className="button-1" style={{color: styleStore.primaryColor}}
                         onClick={processPropertyUpdates}>SUBMIT
                    </div>
                </div>
            </div>;
            break;
        case 'resetColor':
            title = "Reset color";
            content = <div className="dialog-content">
                <p style={{marginTop: 0}}>Are you sure you want to reset the colors?</p>
                <div className="buttons">
                    <div className="button-1" style={{color: styleStore.primaryColor}}
                         onClick={closeDialog}>CANCEL
                    </div>
                    <div className="button-1" style={{color: styleStore.primaryColor}}
                         onClick={() => processPropertyUpdates(true)}>YES
                    </div>
                </div>
            </div>;
            break;
        case 'newList':
            title = "Create list";
            content = <CreateListDialogHooks
                closeDialog={closeDialog}
            />;
            break;
        case 'revoke':
            title = "Revoke access";
            content = <div className="dialog-content">
                <p style={{marginTop: 0}}>Are you sure you want to revoke access for <span>{props.person.name}</span>? They won't be able to access or edit this list until they are re-invited.</p>
                <div className="buttons">
                    <div className="button-1" style={{color: styleStore.primaryColor}}
                         onClick={closeDialog}>CANCEL
                    </div>
                    <div className="button-1" style={{color: styleStore.primaryColor}}
                         onClick={() => revokeAccess(props.person.id)}>REVOKE
                    </div>
                </div>
            </div>;
            break;
        default:
            title = "Unknown error occurred";
            content = null;
            break;
    }

    if (props.openDialog !== null)
        dialog =
            <CSSTransition
                classNames="dialog"
                timeout={500}>
                <div className="dialog">
                    <div className="dialog-title-container">
                        <h1>{title}</h1>
                    </div>
                    {content}
                    {store.loading ? <div className="loader">
                        <div className="loader-bar"
                             style={{backgroundColor: styleStore.primaryColor}}/>
                    </div> : null}

                </div>
            </CSSTransition>;


    return (
        <TransitionGroup>
            {dialog}
        </TransitionGroup>
    )
});

