import env from '../../env';

class HistoryService {
    async getHistoryFromLogs(id, idToken) {
        try {
            const res = await fetch(`${env.BASE_URL}/v2/list/${id}/history`, {
                method: "GET",
                headers: {
                    "Authorization": idToken === null || idToken === undefined ? null : `Bearer ${idToken}`,
                    "Content-Type": "application/json; charset=utf-8",
                },
                referrer: "no-referrer",
            });
            const json = await res.json();
            console.log(json);

            switch (res.status) {
                case 200:
                    return json;
                case 401:
                    return json.error;
                default:
                    return 'error';
            }
        } catch (err) {
            console.log(err);
            return 'error';
        }
    }
}

export default new HistoryService();