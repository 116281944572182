import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import StatsService from "../../services/ListService";
import TagService from "../tags/TagService";
import Sharks from "../../components/Sharks";
import "./StatsPage.scss";
import FirebaseService from "../../services/FirebaseService";
import NoAccessPage from "../../components/NoAccessPage";

class StatsPage extends Component {
    constructor(props) {
        super(props);

        document.title = "Listshare Stats";

        this.state = {
            loaded: false,
            contributions: [],
        }
    }

    async componentDidMount() {
        FirebaseService.getIdToken(async idToken => {
            const properties = await TagService.getProperties(this.props.match.params.listID, idToken);

            if (properties === "NO ACCESS") {
                this.setState({
                    listRequestResult: "no-access"
                });
                return
            }

            if (properties === "error") {
                this.setState({
                    listRequestResult: "error"
                });
                return
            }

            let contributions = await StatsService.getContributions(this.props.match.params.listID, idToken);

            contributions = this.convertContributions(contributions);

            this.setState({
                loaded: true,
                contributions,
                properties
            });
        });
    }

    convertContributions = contributions => {
        const added = contributions.added;
        const deleted = contributions.deleted;

        let stats = [];
        for (let key in added) {
            if (key === "anonymous") continue;
            stats.push({name: key, added: added[key] || 0, deleted: deleted[key] || 0})
        }

        stats.sort((a, b) => {
            const totalA = a.added + a.deleted;
            const totalB = b.added + b.deleted;

            if (totalA < totalB) {
                return 1;
            }

            if (totalA > totalB) {
                return -1;
            }

            return 0;
        });

        return stats;
    };


    getPersonalText = (index, name) => {
        switch (index) {
            case 0:
                return <p>Congratulations {name.split(" ").shift()}, you're the top contributor of {this.state.properties.title}! You can add this to your resume.</p>;
            case 1:
                return <p>Second place. Not too shabby. Keep it up {name.split(" ").shift()}.</p>;
            case 2:
                return <p>At least you're up on the leaderboards...</p>;
            default:
                return null;
        }
    };


    render() {
        const cards = this.state.contributions.length > 0 ? this.state.contributions.map((person, index) =>
            <div className="card" key={index}>
                {index === 0 ? <FontAwesomeIcon style={{float: "left", marginRight: "8px"}} icon="trophy" size="xs"
                                                color={this.state.properties.primaryColor}/> : null}
                <h4>{person.name}</h4>
                {this.getPersonalText(index, person.name)}
                <div className="pills">
                    <div className="pill green">{person.added} added</div>
                    <div className="pill red">{person.deleted} deleted</div>
                </div>
            </div>
        ) : <div className="card">
            <h4>Looks like there's nothing here yet...</h4>
            <p>Adding and deleting don't count when you're not logged in.</p>
        </div>;

        const content = this.state.loaded ?
            <div className="tag-page" style={{paddingTop: "52px"}} onClick={this.handleRemoveSelectionOfTags}>
                <div className="tag-explanation">
                    <h1>Fancy stats for fancy people</h1>
                    <p>Feast your eyes on these unnecessary numbers that our data scientist worked weeks on to
                        complete.</p>
                </div>
                <Sharks/>
                <div className="tag-setup contributors">
                    <h1>Top contributors</h1>
                    <div className="cards">
                        {cards}
                    </div>
                </div>
            </div>
            : null;

        if (this.state.listRequestResult === "no-access" || this.state.listRequestResult === "error") {
            return (
                <NoAccessPage type={this.state.listRequestResult} listID={this.props.match.params.listID}/>
            )
        }

        return (
            <div>
                <div className="ls-header"
                     style={{backgroundColor: this.state.loaded ? this.state.properties.primaryColor : "#f3f3f3"}}>
                    <div style={{display: 'contents'}} className="hamburger-icon-div">
                        <FontAwesomeIcon icon="angle-left" color={this.state.loaded ? "white" : "#424242"} size="lg"
                                         onClick={() => this.props.history.push(`/${this.props.match.params.listID}`)}/>
                        <div className="ls-header-title user-select-none"
                             style={!this.state.loaded ? {color: "#424242"} : null}>{this.state.loaded ? `Stats of ${this.state.properties.title}` : "Loading stats"}</div>
                    </div>
                </div>
                {content}
            </div>
        )
    }
}

export default StatsPage;
