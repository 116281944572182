import React, {Component} from "react";

export default class TableSection extends Component {
    render() {
        return (
            <div className="ls-table-group">
                <h4>{this.props.title}</h4>
                {this.props.children}
            </div>
        )
    }
}

