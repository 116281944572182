import React, {Component} from 'react';
import './RegisterPage.scss';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import "firebase/auth";

class RegisterPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailRegistration: false,
        };

        this.uiConfig = {
            // Popup signin flow rather than redirect flow.
            signInFlow: 'popup',
            // We will display Google and Facebook as auth providers.
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
            ],
            signInSuccessUrl: this.props.match.params.listID === undefined ? '/register' : "/" + this.props.match.params.listID,
        };
    }

    // Listen to the Firebase Auth state and set the local state.
    componentDidMount() {
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => this.setState({isSignedIn: !!user})
        );
    }

    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render() {

        return (
            <div className="register-page">
                <div className="register-header">
                    <div className="register-header-content">
                        <h1>Create account</h1>
                        <p>Create an account to get access to extra features such:</p>
                        <ul>
                            <li>Secured lists</li>
                            <li>Sync saved lists across devices and browsers</li>
                        </ul>
                    </div>

                    <div className="ls-sharks">
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>

                    </div>
                </div>

                <div className="register-content">
                    <div className="register-page-content">
                        {this.state.isSignedIn ? <div>
                            <h1>Welcome {firebase.auth().currentUser.displayName}</h1>
                            <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
                        </div> : <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>}
                    </div>

                </div>
            </div>
        )
    }
}

export default RegisterPage;
