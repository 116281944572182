import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import 'emoji-mart/css/emoji-mart.css'
import {Link, Redirect} from 'react-router-dom';
import {Picker} from 'emoji-mart'

import './TagPage.scss';
import TagService from "./TagService";
import ListService from "../../services/ListService";
import Sharks from "../../components/Sharks";
import FirebaseService from "../../services/FirebaseService";
import NoAccessPage from "../../components/NoAccessPage";

class TagPage extends Component {
    constructor(props) {
        super(props);

        document.title = "Listshare Tags";

        this.state = {
            emojiPickerOpen: false,
            tags: [],
            maxTagsLength: 14,
            properties: {
                primaryColor: '#6523F3'
            }
        };

        this.myRef = React.createRef();
        this.plusClick = this.plusClick.bind(this);
    }

    async componentDidMount() {
        FirebaseService.getIdToken(async idToken => {
            const properties = await TagService.getProperties(this.props.match.params.listID, idToken);

            if (properties === "NO ACCESS") {
                this.setState({
                    listRequestResult: "no-access"
                });
                return
            }

            if (properties === "error") {
                this.setState({
                    listRequestResult: "error"
                });
                return
            }

            this.setState({
                tags: properties.tags.map(tag => {
                    return {
                        emoji: tag
                    }
                }),
                properties: properties
            });
        });
    }

    plusClick = () => {
        if (this.state.tags.length >= this.state.maxTagsLength) {
            return;
        }

        this.setState({emojiPickerOpen: !this.state.emojiPickerOpen});
        setTimeout(() => {
            // const myDomNode = ReactDOM.findDOMNode(this.myRef.current);
            // myDomNode.scrollIntoView({behavior: "smooth", alignToTop: false});
            window.scrollBy(0, 442);
        }, 10);
    };

    pickEmoji = (emoji) => {
        const tags = this.state.tags;
        tags.push({emoji: emoji.native, selected: false});
        this.setState({
            emojiPickerOpen: false,
            tags
        });
    };

    handleDeleteTagClicked = (event, tag) => {
        event.stopPropagation();
        this.setState({
            tags: this.state.tags.filter(it => it.emoji !== tag.emoji)
        });
    };

    handleRemoveSelectionOfTags = (event) => {
        event.stopPropagation();
        this.setState({
            tags: this.state.tags.map(it => {
                it.selected = false;
                return it;
            })
        })
    };

    handleEnableSelection = (e) => {
        e.stopPropagation();
        const tags = this.state.tags.map(it => {
            it.selected = !it.selected;
            return it;
        });
        this.setState({
            tags: tags
        })
    };

    handleSubmitTags = async () => {
        const properties = this.state.properties;
        properties.tags = this.state.tags.map(tag => tag.emoji);
        await ListService.updateProperties(this.props.match.params.listID, properties);
        this.setState({
            toList: true
        })
    };

    render() {
        if (this.state.toList) {
            return <Redirect to={"/" + this.props.match.params.listID} />
        }

        if (this.state.listRequestResult === "no-access" || this.state.listRequestResult === "error") {
            return (
                <NoAccessPage type={this.state.listRequestResult} listID={this.props.match.params.listID}/>
            )
        }

        return (
            <div className="tag-page" onClick={this.handleRemoveSelectionOfTags}>
                <div className="tags-without-bottom">
                    <div className="tag-explanation">
                        <h1>Tags</h1>
                        <p>You can use tags to order your lists. You can set up to {this.state.maxTagsLength} different
                            tags
                            which you can use when
                            adding items.</p>
                        <p>To give an example: each tag can serve as a different aisle in the supermarket. You have the
                            ability to sort your list by tag, which makes doing groceries even easier!</p>
                    </div>

                    <Sharks/>

                    <div className="tag-setup">
                        <h1>Your tags <FontAwesomeIcon color={this.state.properties.primaryColor} icon="tag"/></h1>

                        <div className={this.state.emojiPickerOpen ? "tags tags-margin" : "tags"}>
                            {this.state.tags.map((tag, i) => <div className="tag-wrapper" key={i}>
                                    <div
                                        className={tag.selected ? "tag tag-selected" : "tag"}
                                        onClick={this.handleEnableSelection}>{tag.emoji}
                                    </div>
                                    {tag.selected ?
                                        <div className="tag-delete" onClick={(e) => this.handleDeleteTagClicked(e, tag)}>
                                            ✕</div> : null}
                                </div>
                            )}

                            {this.state.tags.length < this.state.maxTagsLength ? <div className="tag-wrapper">
                                <div className="tag tag-plus" ref={this.myRef}
                                     onClick={() => this.plusClick()}>{this.state.emojiPickerOpen ? "✕" : "+"}</div>
                            </div> : null}

                            {this.state.emojiPickerOpen ? <Picker
                                title='Pick an emoji'
                                emoji='point_up'
                                style={{
                                    position: 'fixed',
                                    bottom: '0',
                                    left: '0',
                                    width: '100%'
                                }}
                                color={this.state.properties.primaryColor}
                                onSelect={this.pickEmoji}
                            /> : null}
                        </div>
                    </div>
                </div>
                <div className="buttons">
                    <div className="button-1" style={{color: this.state.properties.primaryColor}}>
                        <Link style={{color: this.state.properties.primaryColor}}
                              to={`/${this.props.match.params.listID}`}>CANCEL</Link>
                    </div>
                    <div className="button-1" style={{color: this.state.properties.primaryColor}}
                         onClick={this.handleSubmitTags}>SAVE
                    </div>
                </div>
            </div>
        )
    }
}

export default TagPage;
