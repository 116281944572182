import React, {Component} from 'react';
import './LandingPage.scss';
import Logo from '../../img/ls-logo.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StorageService from "../storage/StorageService";
import {CreateListDialogHooks} from "../menu/CreateListDialogHooks";
import {MenuDialog} from "../menu/MenuDialogs";


class LandingPage extends Component {
    state = {
        savedLists: [],
        shouldShowLandingPage: false,
        openDialog: null,
    };

    componentWillMount() {
        // Open last opened list if in PWA
        if (window.matchMedia('(display-mode: standalone)').matches) {
            const lastOpened = StorageService.getLastOpenedList();
            if (lastOpened === null || lastOpened === undefined) {

                this.setState({
                    shouldShowLandingPage: true
                });
                return;
            }

            this.props.history.replace(`/${lastOpened}`);
        } else {
            this.setState({
                shouldShowLandingPage: true
            });
        }
    }

    async componentDidMount() {
        const lists = await StorageService.getLists(null, null);
        this.setState({
            savedLists: lists.map(listID => {
                return {
                    selected: false,
                    listID
                }
            })
        });
    }

    render() {
        if (!this.state.shouldShowLandingPage) {
            return <div/>;
        }

        const savedLists = this.state.savedLists.map(list => {
            return <div className="saved-list-flex"
                        key={list.listID}>
                <div className="saved-list"
                     style={{
                         backgroundColor: list.listID === this.props.id ? this.state.tempPrimaryColor : this.props.darkModeOn ? null : "#D9D9D9",
                         color: list.listID === this.props.id ? "white" : this.props.darkModeOn ? null : "black"
                     }}
                     onClick={() => {
                         window.location.href = `/${list.listID}`
                     }}>
                    {list.listID}
                </div>
                {list.selected ?
                    <div className="saved-list-select" style={{color: this.state.tempPrimaryColor}}><FontAwesomeIcon
                        icon="check"/></div> : null}
            </div>
        });

        return (
            <div className="landing-page">
    
                <div
                    className={this.state.openDialog === null ? "dialog-background display-none" : "dialog-background high-z-index"}
                    onClick={() => this.setState({ openDialog: null })}/>
                    
                <div className="top gray">
                    <h1>Hey there!</h1>
                    <h2>Thanks for using Listshare 😎</h2>

                    <div className="sharks">
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                        <div className="shark"/>
                    </div>
                </div>
                <div className="content">
                    <img src={Logo} alt="Listshare logo" id="logo"/>
    
                    <div className="button-1"  onClick={() => this.setState({ openDialog: 'newList' })}>
                        JOIN LIST
                    </div>
    
                    <MenuDialog resetOpenDialog={() => this.setState({ openDialog: null })} openDialog={this.state.openDialog}/>

                    <h4>YOUR LISTS</h4>
                    <div className="saved-lists">
                        {savedLists}
                    </div>
                </div>
            </div>
        )
    }
}

export default LandingPage;

