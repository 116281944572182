import React from "react";

export const insertLinksInText = (text, primaryColor) => {
    const splitted = text.split(" ");

    return splitted.map(split => {
        if (split.startsWith("http://") ||
            split.startsWith("https://") ||
            split.startsWith("www.")) {
            // it's a website
            if (split.startsWith("www.")) {
                split = `http://${split}`
            }
            return <a href={split} target="_blank" rel="noopener noreferrer"
                      style={{color: primaryColor}}>{split} </a>
        }

        return split + " "
    });
};