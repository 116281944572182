import React, {useRef, useEffect} from "react";

import {observer, useLocalStore} from "mobx-react-lite";
import {useStyleStore} from "../../stores/PropertyStore";
import LSSwitch from "../../components/LSSwitch";
import ListService from "../../services/ListService";

export const CreateListDialogHooks = observer(props => {
    const styleStore = useStyleStore();
    const listNameInput = useRef(null);

    const store = useLocalStore(() => ({
        inputValue: "",
        inviteOnly: false,
        created: false,
        countdown: null,
        conflict: false,
    }));

    useEffect(() => {
        setTimeout(() => {
            listNameInput.current.focus();
        }, 300)
    }, []);

    const handleInputChanged = event => {
        let value = event.target.value;

        if (value.length > 36) {
            value = value.slice(0, -1);
        }
        value = value.replace(/[^a-z0-9]/gi, '-').toLowerCase();
        store.inputValue = value;
    };

    const handleSubmit = async () => {
        if (store.created) {
            return window.location.href = `/${store.inputValue}`;
        }

        if (store.conflict) return;

        try {
            const res = await ListService.createList(store.inputValue, styleStore.idToken, store.inviteOnly);

            if (res.status === 200) {
                store.created = true;
                store.countdown = 5;

                setInterval(() => {
                    store.countdown = store.countdown - 1;
                    if (store.countdown === 0) {
                        window.location.href = `/${store.inputValue}`;
                    }
                }, 1000);

                return;
            }

            if (res === "duplicate") {
                store.conflict = true;
            } else {
                store.created = false;
            }
        } catch (err) {
            store.created = false;
        }
    };


    let content;

    if (store.created) {
        content = <>
            <div className="flex-center">
                <h1 style={{padding: "0 16px"}}>List {store.inputValue} successfully created!</h1>
                <p>Redirecting you to your new list in <span>{store.countdown} seconds.</span></p>
            </div>
        </>
    }

    if (!store.created) {
        if (styleStore.signedIn) {
            content = <>
                <p style={{marginTop: 0}}>{store.inviteOnly ? "Only people you invite can join your list." : "Everyone can join your list if they know the name."}</p>
                <div className="switch-group">
                    <div className="switch-label">Invite only</div>
                    <LSSwitch handleChecked={checked => store.inviteOnly = checked}
                              primaryColor={styleStore.primaryColor}/>
                </div>
                <input ref={listNameInput} type="text" placeholder="Type name here..."
                       onChange={handleInputChanged}
                       style={{alignSelf: "stretch"}} value={store.inputValue}/>
            </>
        } else {
            content = <>
                <p style={{marginTop: 0}}>Choose a unique name for your list!</p>
                <input
                    ref={listNameInput}
                    type="text"
                    placeholder="Type name here..."
                    onChange={handleInputChanged}
                    value={store.inputValue}
                    maxLength={36}
                />
            </>
        }
    }

    if (store.conflict) {
        content = <>
            <p style={{marginTop: 0}}>Looks like the list <span
                style={{fontStyle: "italic"}}>{store.inputValue}</span> already exists. Do you want to join it?
            </p>
        </>
    }

    return (
        <div className="dialog-content dialog-title">
            {content}

            <div className="buttons">
                {store.conflict ?
                    <>
                        <div className="button-1 button-secondary"
                             style={store.created ? {display: "none"} : null}
                             onClick={() => store.conflict = null}>BACK
                        </div>
                        <div className="button-1" style={{
                            color: "white",
                            backgroundColor: styleStore.primaryColor,
                            textAlign: "center",
                            width: "100%",
                            marginLeft: "12px"
                        }} onClick={() => window.location.href = `/${store.inputValue}`}>JOIN LIST
                        </div>
                    </>
                    :
                    <div className="button-1 button-secondary" style={store.created ? {display: "none"} : null}
                         onClick={props.closeDialog}>CANCEL
                    </div>
                }
                {!store.conflict ?
                    <div className="button-1" style={store.created ? {
                        color: "white",
                        backgroundColor: styleStore.primaryColor,
                        textAlign: "center",
                        width: "100%",
                        marginLeft: "12px"
                    } : {color: styleStore.primaryColor}}
                         onClick={handleSubmit}>{store.created ? "GO THERE NOW" : "CREATE"}
                    </div>
                    : null
                }
            </div>
        </div>
    )
});

