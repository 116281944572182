import React, {useRef} from "react";

import {observer, useLocalStore} from "mobx-react-lite";
import {TagPicker} from "./TagPicker";
import ListService from "../../services/ListService";
import {useStyleStore} from "../../stores/PropertyStore";

export const ListInput = observer(props => {
    const propertiesStore = useStyleStore();
    const inputRef = useRef(null);

    const store = useLocalStore(() => ({
        selectedTag: null,
        tagsOpen: false,
        inputValue: null,
    }));

    const focusOnInput = () => {
        inputRef.current.focus();
    };

    const handleInputSend = e => {
        if (e.key === "Enter") {
            const itemName = e.target.value;

            if (itemName === "" || itemName === " " || itemName === null)
                return;

            e.persist();

            ListService.insertItem(propertiesStore.id, itemName, store.selectedTag, propertiesStore.idToken).then(id => {
                if (id === 'anErrorCodeWhichWillNeverOccurEverInTheHistoryOfTheUniverse') {
                    // TODO: Handle error
                } else {
                    // Add props function for updating items
                    props.addNewItemLocally(id, itemName, store.selectedTag);
                    store.inputValue = null;
                    store.selectedTag = null;
                    e.target.value = null;
                }
            });
        }
    };

    const handleInputChange = e => {
        if (e.target.value === "" && store.tagsOpen) {
            store.tagsOpen = false;
        }

        store.inputValue = e.target.value;
    };

    return (
        <div className="input-group">
            <input onKeyUp={handleInputSend} onChange={handleInputChange} type="text"
                   enterkeyhint="send"
                   placeholder="Type here to add a new item" ref={inputRef}/>

            <TagPicker focusOnInput={focusOnInput} store={store}/>
        </div>
    )
});

